let newdomain = process.env.VUE_APP_APIURL

export function enviarRegFichaje(data) {
  let form = new FormData();
  let claves = Object.keys(data);
  claves.forEach((clave) => {
    form.append(clave, data[clave]);
  })
  return fetch(newdomain+'/fichajeExterno', {
    method: 'POST',
    body: form
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log('Error:', error);
    });
}

export function enviarRegFichajeImagen(data) {
  let form = new FormData();
  let claves = Object.keys(data);
  claves.forEach((clave) => {
    form.append(clave, data[clave]);
  })
  return fetch(`${newdomain}/fichajeExterno`, {
    method: 'POST',
    body: form,
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export function enviarRegFichajeResp(data) {
  return fetch(newdomain+'/fichajeExternoResp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log('Error:', error);
    });
}

export function fichajeAusenciaResp(data) {
  return fetch(newdomain+'/fichajeAusenciaResp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log('Error:', error);
    });
}

export function VerificarExistenciaEmp(data) {
  return fetch(newdomain+'/buscarEmpleado', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}

export function ObtenerRegistros(data) {
  return fetch(newdomain+'/obtenerRegistros', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}

export function validarPin(data) {
  return fetch(newdomain+'/validarPin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}


export function validarTokenRecover(data) {
  return fetch(newdomain+'/validarTokenRecover', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}

export function cambiarEstado(data) {
  return fetch(newdomain+'/cambiarEstado', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}

export function verEmpleados(data) {
  return fetch(newdomain+'/verEmpleados', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })

    .catch(error => {
      console.log('Error:', error);
    });
}