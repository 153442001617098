import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/es'
import App from './App.vue';
import VueMqtt from 'vue-mqtt'

/* Import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* Import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* Import all icons */
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

/* Add all icons to the library */
library.add(fas);
library.add(far);
library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueMqtt, process.env.VUE_APP_WSDOM, {clientId: 'WebClient-' + parseInt(Math.random() * 100000)})
Vue.use(ElementUI, { locale });

new Vue({
  el: '#app',
  render: h => h(App)
});